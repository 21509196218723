import {
    OpenApiAdmin,
    ResourceGuesser,
    InputGuesser,
    CreateGuesser,
    EditGuesser,
    ShowGuesser,
    ListGuesser,
    FieldGuesser,
} from "@api-platform/admin";

import mydataProviderFactory from "./Utils/mydataProviderFactory";
import React, {useState, Fragment, Component} from "react";
import {
    fetchUtils,
    RichTextField,
    TextField,
    ArrayField,
    Layout,
    Menu,
    Datagrid,
    TextInput,
    Button,
    BulkDeleteButton,
    BulkExportButton,
    List,
    ListActions,
    TopToolbar,
    CreateButton,
    DeleteButton,
    useUpdateMany,
    BulkUpdateButton,
    useDataProvider
} from 'react-admin';
import { RichTextInput } from 'ra-input-rich-text';
import { parseOpenApi3Documentation } from "@api-platform/api-doc-parser";
import {
    FirebaseAuthProvider,
    // FirebaseDataProvider,
    // FirebaseRealTimeSaga
} from 'react-admin-firebase';

import restDataProvider from "./restDataProvider";

const ENTRYPOINT = process.env.REACT_APP_ENTRYPOINT;
const DOC_ENTRYPOINT = process.env.REACT_APP_DOC_ENTRYPOINT;

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const authProvider = FirebaseAuthProvider(config);

const httpClient = async (url, options = {}) => {
    let newHeaders = new Headers(options.headers)
    try {
        const user = await authProvider.getAuthUser()

        if (user) {
            const accessToken = user.accessToken
            newHeaders.set('Authorization', `Bearer ${accessToken}`);
        }
    } catch (e) {
        console.error("Bye... not authenticated user", e)
    }

    const newoptions = {
        ...options,
        headers: newHeaders,
    };
    const { status, headers, body, json } = await fetchUtils.fetchJson(url, newoptions);

    return { status, headers: newHeaders, body, json };
}

const StaticContentEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="id" disabled />
        <InputGuesser source="slug" />
        <InputGuesser source="title" />
        <InputGuesser source="content" component={RichTextInput}/>
    </EditGuesser>
);

const CustomRichTextField = props => (
    <RichTextField {...props} sx={{ maxHeight: 300, display: "block", overflow: "hidden" }}  />
)

const StaticContentShow = props => (
    <ShowGuesser {...props}>
        <FieldGuesser source={"id"} />
        <FieldGuesser source={"slug"} />
        <FieldGuesser source={"title"} />
        <FieldGuesser source={"content"} component={RichTextField} />
    </ShowGuesser>
);

const UserList = props => (
<ListGuesser {...props}>
    <FieldGuesser source={"id"} />
    <FieldGuesser source={"firebaseUid"} />
    <FieldGuesser source={"email"} />
    <FieldGuesser source={"phoneNumber"} />
    <FieldGuesser source={"name"} />
    <FieldGuesser source={"avatar"} />
    </ListGuesser>
);

const DrinkLogList = props => (
    <ListGuesser {...props}>
        <FieldGuesser source={"id"} />
        <FieldGuesser source={"date"} />
        <FieldGuesser source={"amount"} />
        <FieldGuesser source={"createdAt"} />
    </ListGuesser>
);

const StaticContentList = props => (
    <ListGuesser {...props}>
        <FieldGuesser source={"id"} />
        <FieldGuesser source={"slug"} />
        <FieldGuesser source={"title"} />
        <FieldGuesser source={"content"} component={CustomRichTextField} />
    </ListGuesser>
);

const ChallengeList = props => (
    <ListGuesser {...props}>
        <FieldGuesser source={"id"} />
        <FieldGuesser source={"name"} />
        <FieldGuesser source={"type"} />
        <FieldGuesser source={"status"} />
        <FieldGuesser source={"penalty"} />
        <FieldGuesser source={"prize"} />
        <FieldGuesser source={"startDate"} />
        <FieldGuesser source={"duration"} />
        <FieldGuesser source={"freebiePerWeek"} />
        <FieldGuesser source={"defaultPerWeek"} />
        <FieldGuesser source={"messageCount"} />
        <FieldGuesser source={"invites"} />
        <FieldGuesser source={"members"} />
        <FieldGuesser source={"createdBy"} />
    </ListGuesser>
);

const CustomTextInput = props => (
    <TextInput {...props} fullWidth multiline/>
)
const BroadcastMessageCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source={"text"} component={CustomTextInput} />
        {/*<InputGuesser source={"image"} />*/}
    </CreateGuesser>
);

const BroadcastMessageList = props => (
    <ListGuesser {...props}>
        <FieldGuesser source={"id"} />
        <FieldGuesser source={"text"} />
        {/*<FieldGuesser source={"image"} />*/}
        <FieldGuesser source={"createdAt"} />
    </ListGuesser>
);

const BroadcastMessageShow = props => (
    <ShowGuesser {...props}>
        <FieldGuesser source={"id"} />
        <FieldGuesser source={"text"} />
        {/*<FieldGuesser source={"image"} />*/}
        {/*<FieldGuesser source={"messages"} />*/}
        <ArrayField label="Messages" source="messages" >
            <Datagrid>
                <TextField source="text" />
                <TextField source="challenge" />
            </Datagrid>
        </ArrayField>
        <FieldGuesser source={"createdAt"} />
    </ShowGuesser>
);

const unsent = { sent: false };

const UnsentQuotesButton = (props) => (
    <BulkUpdateButton
        {...props}
        label="Unsent"
        data={unsent}
    />
);

const PostBulkActionButtons = props => (
    <Fragment>
        <UnsentQuotesButton {...props} />
        <BulkDeleteButton {...props} />
    </Fragment>
);

const UnsentAllButton = () => {
    const dataProvider = useDataProvider();

    return <Button label="Unsent All" onClick={() => dataProvider.unsentAll()} />;
};
// const QuoteOfTheDayList = props => (
//     <ListGuesser {...props} >
//             {/*<FieldGuesser source={"id"} />*/}
//             {/*<FieldGuesser source={"text"} />*/}
//             {/*/!*<FieldGuesser source={"createdBy"} />*!/*/}
//             {/*<FieldGuesser source={"sent"} />*/}
//             {/*<FieldGuesser source={"createdAt"} />*/}
//             {/*/!*<FieldGuesser source={"deletedAt"} />*!/*/}
//
//     </ListGuesser>
// );

// const ListLActionButtons = props => (
//     <ListActions>
//         <UnsentAllButton label="Unsent" {...props} />
//     </ListActions>
// );
const  QuoteOfTheDayListActions = () => (
    <TopToolbar>
        <CreateButton/>
        <UnsentAllButton />
        <DeleteButton/>
    </TopToolbar>
);

const QuoteOfTheDayList = props => (
    <List actions={<QuoteOfTheDayListActions />}>
    {/*<List>*/}
        <Datagrid bulkActionButtons={<PostBulkActionButtons />}>
             <FieldGuesser source={"id"} />
             <FieldGuesser source={"text"} />
             <FieldGuesser source={"sent"} />
             <FieldGuesser source={"createdAt"} />
        </Datagrid>
    </List>
);

setInterval(() => {
    // checkLogin()
}, 1000)

const MyMenu = () => (
    <Menu>
        <Menu.ResourceItem name="challenges" />
        <Menu.ResourceItem name="static_contents" />
        <Menu.ResourceItem name="users" />
        <Menu.ResourceItem name="drink_logs" />
        <Menu.ResourceItem name="quote_of_the_days" />
        <Menu.ResourceItem name="broadcast_messages" />
        <Menu.ResourceItem name="challenge_messages" />
    </Menu>
);

const MyLayout = props => {
    return <Layout {...props} menu={MyMenu} />;
}

export default () => {
    const [redirectToLogin, setRedirectToLogin] = useState(false);

    return (
        <OpenApiAdmin
            dataProvider={mydataProviderFactory({
                dataProvider: restDataProvider(ENTRYPOINT, httpClient),
                entrypoint: ENTRYPOINT,
                docEntrypoint: DOC_ENTRYPOINT,
                mercure: false,
            })}
            layout={MyLayout}
            entrypoint={ENTRYPOINT}
            docEntrypoint={DOC_ENTRYPOINT}
            authProvider={authProvider}
        >
            <ResourceGuesser name={"challenges"} list={ChallengeList} />
            <ResourceGuesser name={"static_contents"} show={StaticContentShow} list={StaticContentList} edit={StaticContentEdit} />
            <ResourceGuesser name={"users"} list={UserList} />
            <ResourceGuesser name={"drink_logs"} list={DrinkLogList} />
            <ResourceGuesser name={"quote_of_the_days"} list={QuoteOfTheDayList} />
            <ResourceGuesser name={"broadcast_messages"} show={BroadcastMessageShow} list={BroadcastMessageList} create={BroadcastMessageCreate} />
        </OpenApiAdmin>
    );
}
